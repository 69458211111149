
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index2VuGd_45lFvnkHJN7gP73cN0zpK9H9Njn1syG4n9SsspoMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/index.vue?macro=true";
import { default as loginRBKfZGuKchNBKfO2dyu0VoXy6n_GbJlBceQCdL_y5o4Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/login.vue?macro=true";
import { default as verifya4aFRI0wqR4sJFPwTW_FlQNI5bmzZFIk2hksxQHFGUsMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/verify.vue?macro=true";
import { default as sitemapF2oG9mllGOQ8_45I6pbnU7DOgJimmcQi77sk8f1uEwCxAMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as about_45usNP0_fFTO6DHsCuCvA6A4dyUSqKcAVJH0_45W_45kj506bxcMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as registerRZtEqFOV2k18mNC4jbjHeJHLBduOLBkqe_45enfq5XM0cMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/register.vue?macro=true";
import { default as complaintsSp6hZ92eWa9ftPYyZv7o9uziSnM61dIseDVoqUOrkBwMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45us6EUFVjDtGq5vX3d0r2tln1aLUVLcCAH6tyd__45TnQ800Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as indexPtYoprHod_i37a4JSqZ8Hu4zY0MOo0XCdZCt88oIk18Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as indexahDWcHlKz5AtbpTRTtkkiWL6mshEmRmkU3VMOZRg1RUMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91slug_93UGldz3c8eqArHWQ7P_9WQyz8XzM2hXsVYrQev6_45xbK0Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as partnershipsCXuXSPDvnbZ2T31y9ilwzI80SqUMUNereXKBIPjn5l8Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as terms_45of_45usepaKY0OLgPNpi3iuxKkuDn5cQ9gPwLscjKZ3mClO3aE4Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as indexXk9FOyYvM_Z5lbJKz4R2ldofHpjfaSh8_PU7rSuQEWoMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as privacy_45policy16XpHYAUs6S1AryU_kKBGLKzXSvp_45z4OAePOewBD_45IoMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as reset_45passwordGd7zUyqcx9ZSq_45_LNEfcW_45QhTlCDeoOVyuhJyA7VUHkMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as indexGWxh2hNn5UrlQOAQJRIBNcqPd3IjOx9b4FFl_Fwn_45WgMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as index6CykdgLF2BaQMsKE1YQliPbDO7hLPq9lEKO9ExN8_45VEMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as forgot_45password5NR24ZJ8BiY7GabcKVsLmtQdXPuq5Kpi7_dPL4Gu7ToMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as error5iAk7FbEZMupi9xEwpvowuFcME_45NZmVK2U_45aO8_45EjqIMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/energy/cdr/error.vue?macro=true";
import { default as indexPecQUq9LtSfEor0Y7XWtopzB_45BEYZ__OG8yb9zHgpJIMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as _91slug_93sshmDj1uFyUFnxiTH_45mnjbRbA3fBHj07dsMHkUeXjRkMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as guidesi_45HiCSPTt17RXxSy0vRac18llFdtRwQcUW9FGCFgGTEMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as collection_45noticeqpZDmsSOICLBJ5fw_FCkaUAk8pPjUUt6SRmDIS8uhgwMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as indexHxdHSXUul_45kLVqom88koTQ34ESOvxbK878XZCtvYEeIMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as set_45passwordxgdVZuliwsY6vRMfW3F9G4umP0hITZJwzk_zK6R70sIMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45backKdtmDlXzPXMVpHuUWre2uNY1VLAYJqxq88Th7ovCJwwMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as mergepeD0896E6_9TbYS18LZxwgmjiRafaT7RlI5e_45QpE5REMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as indexQatP7WkXWLsIxadGyx9jaY9LTeG5QJTvzKGa3e3PrfgMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91slug_93bqqa3d_iDisW_PcqdGgpVN4ntIOPtF4fmRYtlIHLANcMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as post_45sale_45thank_45yourrytExvc7ikCtF9U9pT5QXv3BiTBtvNjhypVPOox_45KAMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as thank_45youcwwDtsWjpO_nZslfnH7lP74xsI5wCCvdPdxvYW8KcBkMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/energy/cdr/thank-you.vue?macro=true";
import { default as indexHacuirGAcFe_7euylG3tlzZFyf0rd5blL4guXafvEg0Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as index6qSH_45zZrLPGkuaXnT0o02JIwIGCa2ZV_g_45PelowR9pMMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91slug_93iw91FYEOhTDsKiLyiSU6WVAlQTIkNYGqlDufqihXVjgMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as indexwS1N0UHWAyxiiUl1W5xJ9CBQAANhq53l649CxuCltXcMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexpq8GZefboBm3UBvlSt29r1mH7jvbup1On4_bJr3v2tQMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as _91author_93Yl42Xj5lb8OgUG1VQ0uNuPwk6aaDVrRyntK00dKOBaQMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as callbacktR3tWjoMKMGtca1GI_450WrKOD4bAsdaBTZvOTBEl_FfEMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as buyer_45protection_45claimgCOZPqJaMRTp8UkHcscdQy1i8_e2wagtilP8XPLKfOIMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as indexwrbJNRC9X0tOnD5mt7JCcYh8LOe5UuWftmcB3dXTovcMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as thank_45you7G2mS44wmduyoxmd_uq68S4raJ5QX68jBO_8pVRy9mkMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as errorFdZwPQc0Ey_rzgkubxBU44I6qUptFzFeUgl0J8UYIekMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexq2jPmiRKmijiW582k3HJh0VN_45Ej4qhDhtkQwq9lqNmsMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as indexdoSKLGWvZLQ7Saf_LcsZlxD3iI8iDCo2idlgb2E6DjQMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as errorfSJ4qDHbE1vpfwdR7FpguNY7bkBPK29319exnIZnthYMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as _91step_93VrMJY7E7lXajHDjaWPW9QfBKaIB_qh4MIS2yzoSTHsgMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsIXQDXLsY_45uiVNBCk0Vm0WFn2Ynhi0lb_mnOV8v4nHBAMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as interest_45rates6nAJuB5VoLzSA2yQoEG3Ijo_457xMkaCoxQYr3yHVxwJUMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91slug_93lvGpNCTx87HIZcNsajgZnsUBBaBk91RPJNgwnYnyFYgMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as indexbJDrKpqPvNxiNA2g_45AFMAJ3OAq3T_45BitnJ3xU8NWhVMMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as account_45settings6C6P3B7A8sb2Bs85F1vc7Af7wDn8QCFsdyx_PZP3S5AMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexuzo8mJCyJpRCuJQs4Eslc1tn_45csZIG_Z4GrRIpkorXAMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as live_45chatLHYx4sicHVPtS9Fd0S_CsGex00yGoZM7o3ANp_sWtNMMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as post_45sale1MNJeZ5wLv1P9v73usYd_45JwnrtKwGNUEqo4iamcGnQIMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as thank_45youhs87vICRsZRmzMdKn4WDr_eMBtsSMxiYJtoVNUCeLpAMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as comprehensive5ciJNEByy21_45_7tMIxSkj9Nu4Sqst_45Stgi1xvrvl7DgMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as _91slug_93ti4l0HuiU2Ia44pL7BFetB6oWNKeDYoBTfHL6pEHwi4Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as addioU5S0igEiHdayUxHT_ycVpcYU_458VOkzLxsuZ_45_45va3IMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as indexjC5lClwptBHSQUljPrW_455A70kin9NfMBPd0hYHghmesMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as _91response_93L2IPN5OwcMV0fjI_45EnTcYy5U3dNDt5QkHX_loyiBQkoMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as schedule_45callbackYH8pgsNQKzLneAAPGEXdyMWMuREs4bpXFO3GyeBKHzgMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as _91slug_93NtlnJNLX_45LiAZUVOyIwQpcSRlinxYeIP_45AknmRDsx4kMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as thank_45youXoFgH7aOsZXPF8TK6u_45Lz01mln4IA0EnuQmA7QjJj_45AMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as live_45chat_45bIjcR7nM1v1tz6uM3p4kwhhnooHRALXl_F3rESR_45qaioMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as indexMk_VaEF9aKkLwFcNyHuoAsCwYvr8yGZfC_yy4o_45gtPkMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as _91step_93oQ2oU_45DmRrpqfV5lqEvwos0n680Z4o9xEeTWRvTvuS8Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93GHKW93JTayr2wmH71D1vHfmTxJ8w5Ga0KGQ0Hht1EvcMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue?macro=true";
import { default as indexzVdYOmpAinfa9cSXyO7siHM0xjJ6oiunEw_Npo8I_xwMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addDoEmtX9LXG0tTKTHYg7i34N5vbY_45L4CPvJTnHJaIblgMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as _91type_93mZpEQvwbJfE_45CbsMsJUrUFS_45a1s4ubMGREo6qiw_45WykMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as detailsdJc0lVr1nekD3vwxgriEKkXR4aZkJwuuXrwRyxA40FQMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as callback0TXS3szRk9bRwnqPc9uIUB6KlajqBTyie4cPjog2c6wMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/quote/callback.vue?macro=true";
import { default as digital_45eicpqPwb_TLb9ZOANLZO7rf_8EflYE2B8q6d7g0wDTJoPwMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as thank_45youaCr4NOTrq7eADRlCx2qe5Ekwei1QqNZq2MyIfWzcd_45UMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as _91step_93dg59lavFKHe68KRmJRYNPJ0DRMaAG4JN_5PXUSKUOk4Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as _91author_933vHYjCEo4XVpkYzJMQncQ0R3LsDwAvewhi9j0WqGoq0Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as comparisonhhAD_45pGzUMDCkMcTl0R6IBPy6UUw5ULtPyJL3emsX5QMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/quote/comparison.vue?macro=true";
import { default as step13ZPc_45qWaCaKL50zioJ2gOhNm1uMkelogyllp_45mMTK5gMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue?macro=true";
import { default as detailsXoMsN_45KQ7fmyrQXLGj3B7cWUGU19MBCkQjlVVs0_45kGgMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultsZ1XbNPXUkzfmDY7G08_EqYB8cG8F4231ZJwD_45Pkmaw8Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexGxw0DD_pvC2lh_7KC3DPoibdQSzIZ1a3LIH97lz7eysMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as signup9ieH6rHYRGvJtm2IvbJ9jyeHAOY4Xi_UhzcbHruXvkMMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as thank_45youUw6c8HdRLI5PsUXOOahS9dTEd4hxk8h0sg6fUDFPK3QMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as _91step_93uqALA3PkSTxgN9_45KqdbNrVvTGTQKtidUO3t4sIH2yysMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93cyzd_45xoMuUkYLUSrAj237CqgpIOsIBzLxMcim0kR3ysMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as step03sAmAOXEc_453mzeTUrQzXN0kLZKJ4mVMDkOS7X2iWxa0Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as existing_45customers0BIUvs4lcvYeRbYwH7GQgPmA0XyQ7o_45JfS5NDBnYG_sMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as thank_45youh8_EX6WN__45reqTUt7zXX3SSjYCOedaXkGo8cltRocGQMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as detailsKuAOkarLneZA05RPo80Zxg5n20k1vaEvzwaQNTSqerQMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as details3BLSnx_vRzRA2bGqdOUt2jx_LmneTo4vUx5te6WhFugMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as _91step_93VCxkT8XalefB0jvdyOkqfbcP4CgkrKI61_z4MlGNk4sMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as rebate6DDbscuD5sG2v3q7kh8gttCHP_EzIakvX84QnL2t_AsMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as _91step_933o6o_45v9yobLSvTqA6cWs39zPQBFS1PcCzC7OePVJE_458Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as detailss80JO02tm6xgVM54quWNt7OaRpTAaz5mDI0DPX_45xOEMMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step0rsw6qZe9C4ioYKQ8Md9BHBSCb2nqw_45a5OAMGSOjB5bgMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as indexbryKb4Y7WzqzbzmJadvuKTFogAjYaWzMoevmBu0xzr0Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as indexQ20JybyzYZCYjj807Hhp_iLKqiPzWhwfN3V_450pKMUpsMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as detailsqTKUDzLrVrHyEAknuqoGYGf_45G_Fp0UNkwII_B5gR2DAMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as terms_45and_45conditionsHmHOhJN26e_45Z7ymt2gh89FFabDelmOx0A6KbRJdRb10Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as _91step_93zEcVRFKX8krE113qf2DaW8eyGuRBinqoxLSq75RQrFQMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/applicationV2/[step].vue?macro=true";
import { default as index7_KliDhJBgHen9WNBLZ_45S5nGL63w1d0bc0tg6PgExaoMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as detailsA0Rkac6JKZbZt3hkksBEUXDYFZPsQ_H6GScI_TyPg1UMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as indexW13KQQT9GVReppdOmtuAnAnGV1Cja0ry8yr6MQgW8c8Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as successWeT9TASJ0xFe8PBboqJbWqk80UmzYoAi3hQ1Qh0yMz4Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as claim_45offer1kgc2J1rGv5Nl_45j9ds_BqOC8QI5C6QZeFcQSL4_45CfsIMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as lic_45detailsMjdSNMTHMmPQXmsB8YXJDi_45LFg2_45FuXENQtrwy30C1YMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as coffs_45harbour9fiU6JEel8aoubhaHU2UV42ntJ_45weMpVZo6DEXTcTfEMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as _91step_93JBISi9vmk1MWMItlecsb8QFp1s1OL_45_458hzPw0KARDOAMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as indexC7OfjawK0oIPVMbY8yf7JfAfwUUcDLIM1QXeSBQTux0Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as _91slug_939PHSq2jbZ_45M_62SpVtr_h9LP7GOuW_45PcQlWDYgex_dYMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as indexgAusn1xChMdveb0HvuA7r1DVDojTAeOBzN_45_xOq0q7QMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as _91step_930eAbG9JUN2INW7SoCmQ9ZYsbr84jRAmRPcLf7BsJMdMMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as thank_45you9heGmS9fJ0NKKndybNL47aXlsOLfo0nbTrFpuuuYXOcMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/quote/application/thank-you.vue?macro=true";
import { default as indexrfFYxX3Yx2lrdYGXUMDUii1cBZcUEjyCvbvbfs3FMxEMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as indexCEYG5V41YSVa3B8k2DgbyKHj7ahuanJwhUS8IUPlFWcMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as detailsAxXfLkYhR_7DV_456qWZmbSjsVkDQ1g7IGV9AgHqhlSBoMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as expiredgGAAJrDrSJUPZRy9ywNnQTX_bGs4y3mQWhXlb979uU0Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexPfvaPniMUUpXtyZ4Vj1Z1RPRs4G6XAY_45XXyws3UexeoMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as thank_45youAIoSaquY3Dhg4BNrUxkgNu9xK8TmB_45_MVPzY_45EYoVDYMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as detailsNWYWCni0zuoGkGTqiBkhJNUEwYERLN545OiQySR9H_45AMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as pre_45assesmentF8R3YJEZ7CR0H8ZpqUra67HsWNCoS59mPld6z24MDJsMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/quote/application/pre-assesment.vue?macro=true";
import { default as confirmation5h6NPx_45qZXn106xRzn2dzJM0T0erjSAdXZ3lDuGYiCcMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as completionp7gWnmfDTdpzGJ1O74vUF6M5TSo2gBZBylWzrfW1Ar8Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as quotes_45resultsXooLAvM_45FBbM6woAEe_45G7F6LsGyE6OiWJziBpn5IvlMMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as confirmationh1nnyf_2bTxoMAVRc482qw8SqGlvwEOWKqGWKAc9U_UMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as _91step_93eFYfHJC9iZGwZbrDiVFX3zY0omv276cFM3mKflOienIMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93OQ7qMzFCnzToHDFbpHXDI1PohsPpnHJZ8EJYYZd7t2UMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93EDX63jNrviFedhPPoTwtnSToto7phMJdi8zn29u8t9IMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as _91step_93x_LIDuOj1v_xbgcIpCR6TlvJtCZpi5SVx5mapgexy78Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as index8XFSpve1s1I5aYEHF79_45rtaRpdsELsQn2Ejz_6xMbWsMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as additional_45detailsmmJwkK_CztwC_Sn4KAjagFWWOrQBG6ZKcnBAvjgUD9sMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as _91step_93k14L9RoGYfTt3cS_orHgCSNhwuSgtsfuSNHe6_45B1_ugMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as _91step_93JTZOaxErflKzLKpeXHLwyAXJzLJvTHai4QmDfjqZTeYMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as indexviGM__A9i7guO752aPK_P6VM5hrFN5ZcN1YAzOsFPR4Meta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as indexGo9eJQu9V5QDMNd2PD6O9N2GLMcxkzhmNjv3OmU6F0sMeta } from "/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/budget-hub/health-insurance-average-cost-calculator/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginRBKfZGuKchNBKfO2dyu0VoXy6n_GbJlBceQCdL_y5o4Meta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerRZtEqFOV2k18mNC4jbjHeJHLBduOLBkqe_45enfq5XM0cMeta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordGd7zUyqcx9ZSq_45_LNEfcW_45QhTlCDeoOVyuhJyA7VUHkMeta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: index6CykdgLF2BaQMsKE1YQliPbDO7hLPq9lEKO9ExN8_45VEMeta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password5NR24ZJ8BiY7GabcKVsLmtQdXPuq5Kpi7_dPL4Gu7ToMeta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "energy-cdr-error",
    path: "/energy/cdr/error",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/energy/cdr/error.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45passwordxgdVZuliwsY6vRMfW3F9G4umP0hITZJwzk_zK6R70sIMeta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93bqqa3d_iDisW_PcqdGgpVN4ntIOPtF4fmRYtlIHLANcMeta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "energy-cdr-thank-you",
    path: "/energy/cdr/thank-you",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/energy/cdr/thank-you.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexwS1N0UHWAyxiiUl1W5xJ9CBQAANhq53l649CxuCltXcMeta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexwrbJNRC9X0tOnD5mt7JCcYh8LOe5UuWftmcB3dXTovcMeta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: indexdoSKLGWvZLQ7Saf_LcsZlxD3iI8iDCo2idlgb2E6DjQMeta?.props ?? {"verificationNeeded":true},
    meta: indexdoSKLGWvZLQ7Saf_LcsZlxD3iI8iDCo2idlgb2E6DjQMeta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settings6C6P3B7A8sb2Bs85F1vc7Af7wDn8QCFsdyx_PZP3S5AMeta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexuzo8mJCyJpRCuJQs4Eslc1tn_45csZIG_Z4GrRIpkorXAMeta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: schedule_45callbackYH8pgsNQKzLneAAPGEXdyMWMuREs4bpXFO3GyeBKHzgMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  },
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  }
]
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "car-insurance-redirect-slug",
    path: "/car-insurance/redirect/:slug()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "life-insurance-quote-callback",
    path: "/life-insurance/quote/callback",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/quote/callback.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "life-insurance-quote-comparison",
    path: "/life-insurance/quote/comparison",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/quote/comparison.vue")
  },
  {
    name: "life-insurance-quote-form-step1",
    path: "/life-insurance/quote/form/step1",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product([^/]*)*/rebate",
    meta: rebate6DDbscuD5sG2v3q7kh8gttCHP_EzIakvX84QnL2t_AsMeta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product([^/]*)*/details",
    meta: detailsqTKUDzLrVrHyEAknuqoGYGf_45G_Fp0UNkwII_B5gR2DAMeta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "health-insurance-applicationV2-step",
    path: "/health-insurance/applicationV2/:step()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/applicationV2/[step].vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offer1kgc2J1rGv5Nl_45j9ds_BqOC8QI5C6QZeFcQSL4_45CfsIMeta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product([^/]*)*/lic-details",
    meta: lic_45detailsMjdSNMTHMmPQXmsB8YXJDi_45LFg2_45FuXENQtrwy30C1YMeta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_93JBISi9vmk1MWMItlecsb8QFp1s1OL_45_458hzPw0KARDOAMeta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "life-insurance-quote-application-thank-you",
    path: "/life-insurance/quote/application/thank-you",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/quote/application/thank-you.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-quote-application-pre-assesment",
    path: "/life-insurance/quote/application/pre-assesment",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/quote/application/pre-assesment.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/completion",
    meta: completionp7gWnmfDTdpzGJ1O74vUF6M5TSo2gBZBylWzrfW1Ar8Meta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/energy/step/:step()",
    meta: _91step_93eFYfHJC9iZGwZbrDiVFX3zY0omv276cFM3mKflOienIMeta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/generic/step/:step()",
    meta: _91step_93OQ7qMzFCnzToHDFbpHXDI1PohsPpnHJZ8EJYYZd7t2UMeta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/home-loan/step/:step()",
    meta: _91step_93x_LIDuOj1v_xbgcIpCR6TlvJtCZpi5SVx5mapgexy78Meta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/additional-details",
    meta: additional_45detailsmmJwkK_CztwC_Sn4KAjagFWWOrQBG6ZKcnBAvjgUD9sMeta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/life-insurance/step/:step()",
    meta: _91step_93k14L9RoGYfTt3cS_orHgCSNhwuSgtsfuSNHe6_45B1_ugMeta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/health-insurance/step/:step()",
    meta: _91step_93JTZOaxErflKzLKpeXHLwyAXJzLJvTHai4QmDfjqZTeYMeta || {},
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-budget-hub-health-insurance-average-cost-calculator",
    path: "/health-insurance/budget-hub/health-insurance-average-cost-calculator",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/health-insurance/budget-hub/health-insurance-average-cost-calculator/index.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src1918002103/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]